<template>
	<section class="search text-center">
		<v-container>
			<v-form class="mb-15 d-flex justify-center search__form" @submit.prevent="">
				<div class="search__form-input">
					<input v-model="form.search"
						   maxlength="80"
						   class="d-flex"
						   :placeholder="texts.search">
					<button type="submit">
						<svg width="25" height="24" viewBox="0 0 25 24" fill="none"
							 xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								  d="M11.6523 19C16.0706 19 19.6523 15.4183 19.6523 11C19.6523 6.58172 16.0706 3 11.6523 3C7.23407 3 3.65234 6.58172 3.65234 11C3.65234 15.4183 7.23407 19 11.6523 19Z"
								  stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M21.6527 21.0004L17.3027 16.6504" stroke="black" stroke-width="2"
								  stroke-linecap="round"
								  stroke-linejoin="round"/>
						</svg>
					</button>
				</div>
			</v-form>


			<template v-if="search && filteredProducts.length">
				<h1> {{resultCountText}}</h1>
				<v-row class="justify-center">
					<v-col v-for="(product, index) of filteredProducts" :key="index" cols="12" md="6" lg="4" xl="3">
						<product :item="product" class="mb-16"/>
					</v-col>
				</v-row>
			</template>
			<v-row v-else-if="!search">
				<v-col cols="12" class="text-center">
					<h2 class="mb-15">{{ texts.searchInput }}</h2>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12" class="text-center">
					<h2 class="mb-15">{{ texts.searchResult }}</h2>
				</v-col>
			</v-row>

		</v-container>
	</section>

</template>

<style lang="scss">
	.search {
        padding-top: 180px;

		&__form {
			&-input {
				position: relative;
				width: 240px;

				@include up($sm) {
					width: 400px;
				}

				input {
					height: 40px;
					width: 100%;
					background-color: $additional100 !important;
					box-shadow: $default-shadow;
					border-radius: 20px;
					padding-left: 18px;
					font-size: 18px;
					z-index: 10;
					@include transition();

					&:hover {
						box-shadow: $default-shadow-hover;
					}
				}

				button {
					position: absolute;
					top: 8px;
					right: 14px;
				}
			}
		}
	}
</style>

<script>
    import Product from "../components/product";
    import {debounce} from "lodash";
    import {end} from "@/utils/string";
    import Config from "@/config";

    export default {
        name: 'Search',
        components: {
            Product
        },
        data() {
            return {
				form: {
                    search: ""	// для формы
				},
                search: "",	// для реального поиска
            }
        },
		watch: {
            "form.search"() {
                this.searchDebounced();
			},
			"$route.query"() {
                this.search = this.$route.query['text'];
                this.form.search = this.search;
			}
		},
        computed: {
            resultCountText() {
                const count = this.filteredProducts.length;
                return end(count,
					"Найден 1 продукт",
					"Найдено "+count+" продукта",
					"Найдено "+count+" продуктов");

            },
            products() {
                return this.$store.state.page?.SearchController?.items;
            },
            texts() {
                return {
                    ...this.$store.state.page?.SearchController?.texts,
                    search: this.$store.state.common?.commontext?.SEARCH_PLACEHOLDER,
                    searchInput: this.$store.state.common?.commontext?.SEARCH_INPUT,
                    searchResult: this.$store.state.common?.commontext?.SEARCH_RESULT,
                }
            },
			filteredProducts() {
                return this.products.filter(el=>{
                    return el.NAME.toLowerCase().includes(this.search.toLowerCase());
                })
			}
        },

        methods: {
            searchDebounced: debounce(function () {
                const text = this.form.search.trim();
                if ( text.length >=2 ) this.search = text;
                else this.search = "";
            }, Config.DEBOUNCE_INPUT_TIMEOUT),
		},
		mounted() {
            this.search = this.$route.query['text'];
            this.form.search = this.search;
		}
    }


</script>